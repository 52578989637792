<template>
  <div class="home">
    <div class="alert alert-warning fixed alert-dismissible fade show mb-0 rounded-0 wow" role="alert" v-if="['nick', 'leo'].includes($route.query.i)">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-9 col-sm-10">
            <div class="row">
              <div class="col-2 col-sm-2 align-self-center pr-1">
                <img :src="'/images/' + ($route.query.i === 'leo' ? 'leo' : 'nick') + '.jpg'" width="50" height="50" style="display: block; margin-left: auto;"/>
              </div>
              <div class="col-9 col-sm-9 align-self-center">
                <p class="m-0"><span v-text="$route.query.i === 'leo' ? 'Leo (CEO and Co-founder of CitronWorks)' : 'Nick (CTO and Co-founder of CitronWorks)'"></span> has invited you to try <b>CitronWorks Concierge service with a 25% discount for $449.</b></p>
              </div>
            </div>
          </div>
        </div>
        <button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true"><i class="el-icon-close"></i></span>
        </button>
      </div>
    </div>
    <div class="hero" :class="['nick', 'leo'].includes($route.query.i) ? 'hero-margin' : ''" id="hero">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-6" style="align-self: center;">
            <div class="hero-content">
              <h1 class="wow fadeInUp" data-wow-delay="0s">CitronWorks Concierge</h1>
              <h4 class="wow fadeInUp" data-wow-delay="0.2s">
                Delivering high-quality curated remote workers to your inbox.
              </h4>
              <a v-scroll-to="{ element: '.form',  duration: 1500 }" class="learn-btn" >Inquire now</a>
            </div>
          </div>
          <div class="col-8 col-md-5">
            <img class="hero-img" src="/images/concierge.png" alt="Hero Image" />
          </div>
        </div>
      </div>
    </div>
    <div class="concierge">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-10 col-md-10">
              <el-card class="mb-4 wow fadeIn" style="position: relative; border: none;" :body-style="{ padding: '60px 50px 30px' }">
                <h5 class="ribbon">
                  $999
                </h5>
                <h4 class="bold orange-text text-center ribboned">Welcome to the CitronWorks Employers’ Concierge Service</h4>
                <p>With CitronWorks Concierge, you can get the top freelancers and remote workers delivered right to your inbox. Just tell us what you’re looking for, and we’ll do all the work of finding the top candidates for you to choose from.</p>
                <p>Find freelancers and remote workers who offer copywriting, web development, customer service, personal assistance, social media marketing, and more.</p>
                <p>No fuss. Just great candidates.</p>
                <p class="mb-4">That’s CitronWorks Concierge. 
                </p>
                
                <h4 class="bold text-center orange-text bordered">How it Works</h4>
                <p class="mb-4">CitronWorks Concierge couldn’t be easier to use and to get high-quality freelancers and remote workers ready to help you with your projects.</p>
                <ol>
                  <li class="mt-3"><span class="dropcap">01</span> <span class="single-line">We contact you to get details about your needs.</span></li>
                  <li class="mt-3"><span class="dropcap">02</span> Once you approve all the details, your concierge will conduct the search and supply you with top candidates who meet your requirements.  We can even negotiate rates for you.</li>
                  <li class="mt-3"><span class="dropcap">03</span> <span class="single-line-lg">You contact and interview the shortlisted candidates, and hire the one you like the most.</span></li>
                </ol>
                <p class="mt-4">For one low all-inclusive fee per hire of $599 (entry level) or $999 (regular roles), we deliver the team members you need, or you get your money back.</p>
                <p><i>Includes a 90-day replacement guarantee.</i></p>
              </el-card>
            </div>
          </div>
        </div>
    </div>
    <div class="form beta" id="form">
      <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-6 col-md-8 wow fadeIn">
            <hr>
            <el-card :body-style="{ padding: '20px 30px' }">
              <el-form ref="form" :model="signup">
                <el-row :gutter="20" type="flex" justify="center">
                  <el-col :xs="24" :lg="17" class="text-center">
                    <h4 class="orange-text mb-3 mt-3 bold">Concierge Inquiry</h4>
                    <p class="mb-4">Please complete the form below. We will get back to you to get more details about your needs.</p>
                  </el-col>
                </el-row>
                <el-row :gutter="20" >
                  <el-col :xs="24" :md="12" :sm="12">
                    <el-form-item label="First Name" prop="first_name" class="is-required">
                      <el-input v-model="signup.first_name"></el-input>
                    </el-form-item>

                  </el-col>
                  <el-col :xs="24" :md="12" :sm="12">
                    <el-form-item label="Last Name" prop="last_name">
                      <el-input v-model="signup.last_name"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="12" :sm="12">
                    <el-form-item label="Email Address" prop="email" class="is-required">
                      <el-input v-model="signup.email" type="email"></el-input>
                    </el-form-item>

                  </el-col>
                  <el-col :xs="24" :md="12" :sm="12">
                    <el-form-item label="Phone" prop="phone">
                      <el-input v-model="signup.phone"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="12" :sm="12">
                    <el-form-item label="Company" prop="company">
                      <el-input v-model="signup.company"></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :md="12" :sm="12">
                    <el-form-item label="Company Website" prop="company_website">
                      <el-input v-model="signup.company_website"></el-input>
                    </el-form-item>
                  </el-col>
                  
                  <el-col :xs="24">
                    <el-form-item label="Message" prop="message" class="is-required">
                      <el-input
                        type="textarea"
                        :rows="4"
                        v-model="signup.message">
                      </el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24">
                    <p class="small">* Required</p>
                  </el-col>
                  <!-- <el-col :xs="24">
                    <el-form-item>
                      <el-checkbox name="type" value="tick" v-model="signup.agreement">
                          I accept CitronWorks
                          <el-button type="text" @click="tos = true" class="terms">Terms of Service</el-button>
                          <el-dialog
                            :visible.sync="tos"
                            width="90%">
                            <span slot="title" class="dialog-title">
                              <h4 class="bold orange-text">Terms of Service</h4>
                            </span>
                            <terms-of-service></terms-of-service>
                            <span slot="footer" class="dialog-footer text-center">
                              <el-button @click="tos = false" type="warning">Close</el-button>
                            </span>
                          </el-dialog>
                          and 
                          <el-button type="text" @click="pp = true" class="terms">Privacy Policy</el-button>
                          <el-dialog
                            :visible.sync="pp"
                            width="90%">
                            <span slot="title" class="dialog-title">
                              <h4 class="bold orange-text">Privacy Policy</h4>
                            </span>
                            <privacy-policy></privacy-policy>
                            <span slot="footer" class="dialog-footer text-center">
                              <el-button @click="pp = false" type="warning">Close</el-button>
                            </span>
                          </el-dialog>.
                      </el-checkbox>
                    </el-form-item>
                  </el-col> -->
                  <input type="hidden" v-model="signup.referer" />
                  <!--Show only when submitted-->
                   <el-col :xs="24">
                    <el-alert v-if="status ==='success'" class="mb-3 mt-3"
                      :title="signup.successMessage"
                      type="success"
                      effect="dark">
                    </el-alert>
                  </el-col>
                  <!--Show when there's an error-->
                  <el-col :xs="24">
                    <el-alert v-if="error"
                      title="The following is required:"
                      type="error"
                      :closable="click=false"
                      @close="true"
                      class="signup-alert mb-3 mt-3">
                      <li v-for="first in error['concierge_request.first_name']"
                          :key="first">
                        <i class="el-icon-error"></i> {{ first }}
                      </li>
                      <li v-for="last in error['concierge_request.last_name']" :key="last">
                        <i class="el-icon-error"></i> {{ last }}
                      </li>
                      <li v-for="email in error['concierge_request.email']" :key="email">
                        <i class="el-icon-error"></i> {{ email }}
                      </li>
                      <li v-for="message in error['concierge_request.message']" :key="message">
                        <i class="el-icon-error"></i> {{ message }}
                      </li>
                      <li v-for="phone in error['concierge_request.phone']" :key="phone">
                        <i class="el-icon-error"></i> {{ phone }}
                      </li>
                      <li v-for="company in error['concierge_request.company']" :key="company">
                        <i class="el-icon-error"></i> {{ company }}
                      </li>
                      <li v-for="company_website in error['concierge_request.company_website']" :key="company_website">
                        <i class="el-icon-error"></i> {{ company_website }}
                      </li>
                      <li v-for="referer in error['concierge_request.referer']" :key="referer">
                        <i class="el-icon-error"></i> {{ referer }}
                      </li>
                    </el-alert>
                  </el-col>
                  <el-col :xs="24">
                    <el-form-item class="text-center buttons mt-1">
                      <el-button type="warning" @click="conciergeReq(signup)">
                        Submit
                        <i class="el-icon-loading" v-show="status === 'loading'"></i>
                      </el-button>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-form>
            </el-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { WOW } from 'wowjs'
import {mapGetters, mapActions} from 'vuex';
import $ from 'jquery'
// import TermsOfService from '~/components/Terms/TermsOfService'
// import PrivacyPolicy from '~/components/Terms/PrivacyPolicy'

export default {
  components: {
      // TermsOfService,
      // PrivacyPolicy,
    },
  data() {
    return {
      tos: false,
      pp: false,
      // signup: {
      //   first_name: '',
      //   last_name: '',
      //   email: '',
      //   company_name: '',
      //   company_url: '',
      //   phone: '',
      //   message: '',
      //   referer: !this.$route.query.i ? 'unavailable' : this.$route.query.i ,
      //   // agreement:false
      // },
    }
  },
  beforeMount() {
    this.signup.referer = !this.$route.query.i ? 'none' : this.$route.query.i
  },
  mounted () {
    new WOW().init()
    $(function () {
      $(document).scroll(function () {
        var $alert = $(".alert");
        $alert.toggleClass('scrolled', $(this).scrollTop() > $alert.height());
      });
    });
  },
  computed: {
    ...mapGetters({
       error: 'getConciergeErr'
      , signup:'getConcierge'
      , status: 'authStatus'
    }),
  },
  watch: {
    
  },
  methods: {
    ...mapActions({
      conciergeReq: 'conciergeRequest',
    }),
    checkingRequired() {
      return this.status === 'success' ? false : true;
    }
  }
}
</script>

<style lang="scss" scoped>
button:active, button:focus {
  outline: coral;
}
.form.beta {
  padding: 80px 0;
  // background: #f9f9f7;
  background: linear-gradient(to right, rgba(232, 119, 33, .1), rgba(23, 21, 20, 0.4)), url(/images/signup.jpg) no-repeat center right;
  background-size: cover;

  h4 {
    letter-spacing: 1.1px;
  }
}

.alert button{
  position: absolute;
  top: 15px;
  right: 15px;
  padding: 0;
}
.alert button:active, .alert button:focus{
  outline: none;
}
.learn-btn {
  color: #ffffff !important;
  background-color: #E87722;
  border: 1px solid #E87722;
}
.concierge {
  padding: 60px 0 40px 0;
  background: #f6f6f6;
  border-bottom: 1px solid #d3d3d3;
  
  h4.bordered {
    margin-top: 40px;
    padding-top: 30px;
    border-top: 1px solid #f6f6f6;
    margin-bottom: 1.75rem;
  }
  p {
    font-size: 0.9rem;
    line-height: 1.7;
    letter-spacing: 1.2px;
  }

  .el-card.mb-4 {
    border-radius: 5px !important;
  }
  h4{
    line-height: 1.4;
    margin-bottom: 2rem;
    letter-spacing: 1.1px;
  }
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  ol li {
    width: 100%;
    font-size: 0.9rem;
    margin-bottom: 2rem;
    line-height: 1.7;
    letter-spacing: 1.2px;
  }
  ol li .dropcap {
    float: left;
    font-weight: bold;
    font-size: 3rem;
    margin-right: 0.5rem;
    font-family: 'Abril Fatface', serif;
    line-height: 1;
    color: gray;
  }

  // ol li::before {
  //   content: "0" counter(my-awesome-counter);
  //   font-weight: bold;
  //   font-size: 3rem;
  //   margin-right: 0.5rem;
  //   font-family: 'Abril Fatface', serif;
  //   line-height: 1;
  // }
}
hr {
  margin: 0;
  border-top: #e87722 2px solid;
}
.home {
  overflow: hidden;
}
.terms {
  color: inherit;
  text-decoration: underline;
  padding: 0;
}
.hero {
  padding-top: 80px;
  background: #f9f9f7;
  border-bottom: 1px solid lightgray;
}
.hero-margin {
  padding-top: 140px; 
}

.hero-img{
  max-width: 100%;
  padding: 0 0 0 40px;
}
.hero-content h1 {
  color: #f89827;
  font-size: 46px;
}
.hero-content h4 {
  font-size: 1.155rem;
  color: #576874;
  font-weight: 400;
  line-height: 1.6;
  margin: 0 0 25px 0;
}
.alert.fixed {
  position: fixed;
  z-index: 2003;
  width: 100%;
  // transition: .2s;
  // transition-timing-function: ease-out;
}

.home .el-alert__content li {
  list-style: none;
  padding-left: 8px;
}
@media (min-width: 1200px) { 
  .single-line-lg {
    line-height: 48px;
  }
}
@media (min-width: 768px) {
  .alert.scrolled {
    top: 61px;
    transition-property: top;
    transition: .2s;
    transition-timing-function: ease-out;
  }
  .about {
    padding: 50px 30px;
  }
  .visible-xs {
    display: none;
  }
  .single-line {
    line-height: 48px;
  }
}
@media (min-width: 993px) {
  .hero-content h1 {
      margin: 0 10px 20px 0;
  }
  .visible-sm {
    display: none;
  }
  .about {
    padding: 100px 0px;
  }
}
@media (min-width: 1200px) {

}

@media (max-width: 1199px) {

}
@media (max-width: 992px) {
  
  .hidden-sm {
    display: none;
  }
  #pitch {
    margin-bottom: 10px;
  }
  .cta-sub {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .hero {
    padding-top: 50px;
  }
  .hero-margin {
    padding-top: 100px;
  }
  .hero-content h1 {
    font-size: 32px;
  }
  .hero-content h4 {
    font-size: 1.05rem;
  }
}
@media (max-width: 767px) {
  .concierge {
    padding: 40px 10px 20px;
  }
  
  .alert.fixed {
    padding-right: 0;
    padding-left: 0;

    p { 
      font-size: 0.8rem;
      line-height: 1.5;
    }
  }
  .form.beta {
    padding: 15px 0;
  }
  .visible-xs {
    display: block;
  }
  .hidden-xs {
    display: none;
  }
  .hero {
    padding-top: 40px;
  }
  .hero-margin {
    padding-top: 100px;
  }
  .hero-content {
    padding: 15px 0;
    text-align: center;

    h4, h1{
      text-align: center
    }
  }
  .hero-content h1 {
    font-size: 2rem;
    margin-bottom: 25px;
  }
  .hero-img {
    padding: 0 50px;
  }
  .about {
    padding: 40px 25px;
    overflow: hidden;
  }
  .learn-btn {
    margin: 0 auto 30px !important;
  }
  .about-content h3 {
    font-size: 1.1rem;
  }
  .about-content h2 {
    font-size: 1.75rem;
  }
  .freelancer-row, .employer-row {
    padding: 25px 10px;
    margin: 0 10px 50px 10px;
  }
  .pitch-content h1 {
    margin-top: 10px;
  }
  .signup-form {
    margin: 0 15px;
  }
}
@media (max-width: 320px) {
  
  .concierge h4 {
    font-size: 1.25rem
  }
  .concierge p, .concierge li {
    letter-spacing: 1px;
  }
}
.ribbon {
  margin: 0;
  padding: 0;
  background: coral;
  color:white;
  padding:.5em 1em;
  position: absolute;
  top:0;
  right:0;
  transform: translateX(30%) translateY(0%) rotate(45deg);
  transform-origin: top left;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 1rem;
}
.ribbon:before,
.ribbon:after {
  content: '';
  position: absolute;
  top:0;
  margin: 0 -1px; /* tweak */
  width: 100%;
  height: 100%;
  background: coral;
}
.ribbon:before {
  right:100%;
}

.ribbon:after {
  left:100%;
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
   /* IE10+ specific styles go here */  
   .home {
      margin-top: -15px;
   }
}
</style>

